<template>
    <v-expansion-panels v-model="value" multiple class="mb-6">
        <v-expansion-panel class="v-expansion-panel">
            <v-expansion-panel-header class="fw-500 py-0" style="min-height: 50px;">
                {{ label }} ({{ items.length }})
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <template v-if="!noItems">
                    <div v-for="(item, i) in formattedItems" :key="i" class="event-calendar-legend__item">
                        <v-divider v-if="i > 0" class="my-2" />
                        <div class="d-flex flex-row flex-nowrap">
                            <div class="event-calendar-legend__item-color mr-2" :style="{background: item.color}"></div>
                            <div class="event-calendar-legend__item-data">
                                <div class="d-flex flex-row align-center">
                                    <div class="fs-15 lh-20 fw-500">{{ item.subject }}</div>
                                    <div class="fs-13 lh-20 ml-2">{{ item.groupName || item.grade }} класс</div>
                                </div>
                                <div class="fs-11 fw-500">C {{ item.started_at_formatted }} по {{ item.ended_at_formatted }}</div>
                                <div v-if="item.backup_day" class="fs-11 fw-500">Резервный день: {{ item.backup_day }}</div>
                                <div class="fs-11">{{ getExecutionText(item.execution) }}</div>
                            </div>
                            <v-spacer />
                            <v-btn
                                v-if="isCanEditCalendarSection && removable && item.id"
                                color="red"
                                dark
                                icon
                                :loading="removingIds.includes(item.id)"
                                @click="onRemoveBtnClick(item)"
                            >
                                <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </template>
                <div v-else class="grey--text">На выбранный день не запланировано никаких работ.</div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import retrieveOrLoadMixin from '@/mixins/retrieveOrLoadMixin'
import isUserCanMixin from '@/mixins/isUserCanMixin'

export default {
    name: 'EventCalendarLegend',
    mixins: [retrieveOrLoadMixin, isUserCanMixin],
    props: {
        label: { type: String, default: 'События' },
        items: { type: Array, default: () => [] },
        opened: { type: Boolean, default: false },
        removable: { type: Boolean, default: false }
    },
    data () {
    	return {
            value: [],
            removingIds: []
        }
    },
    computed: {
        groups () {
            return this.$store.state.group.items
        },
        noItems () {
            return _.size(this.items) <= 0;
        },
        formattedItems () {
            return this.items.map(item => {
                return {
                    ...item,
                    groupName: item.group_id && this.groups.length ? this.groups.find(g => g.id === item.group_id)?.name : null
                }
            })
        }
    },
    methods: {
        getExecutionText (code) {
            const dict = {
                'print': 'При проведении на бумажном носителе',
                'digital': 'При проведении с использованием компьютера'
            }
            return dict[code] || ''
        },
        onRemoveBtnClick (item) {
            this.$emit('click:remove', item.id)
            this.removingIds.push(item.id)
        }
    },
    created () {
        this.retrieveOrLoad({ module: 'group', action: 'fetch', fields: 'grade,name,student_id' })
    },
    beforeMount() {
        if(this.opened)
            this.value.push(0);
    }
}
</script>
<style lang="scss" scoped>
.v-expansion-panel::before{
    box-shadow: none!important;
}
.event-calendar-legend__item{
    .event-calendar-legend__item-color{
        flex: 0 0 .5rem;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        margin-top: 5px;
    }
}
</style>