<template>
    <div>
        <div class="text-h6">Календарь ВПР</div>
        <div class="d-flex flex-column">
            <div v-if="loading.fetch" class="d-flex align-center w-100">
                <v-progress-linear
                    rounded
                    height="2"
                    class="w-100"
                    :color="$const.color.primary"
                    indeterminate
                />
            </div>
            <template v-else>
                <v-card title="Фильтр" class="w-100 mb-10 pa-4">
                    <div class="d-flex align-center">
                        <v-select
                            v-if="subjects"
                            v-model="filter.subject"
                            :items="subjects"
                            placeholder="Предмет"
                            append-icon="mdi-chevron-down"
                            solo
                            flat
                            outlined
                            clearable
                            hide-details
                            dense
                            class="mr-5"
                            style="max-width: 300px;"
                            @change="() => onDatePickerInput(value)"
                        ></v-select>

                        <v-select
                            v-if="grades"
                            v-model="filter.grade"
                            :items="grades"
                            placeholder="Параллель"
                            append-icon="mdi-chevron-down"
                            solo
                            flat
                            outlined
                            clearable
                            dense
                            hide-details
                            style="max-width: 300px;"
                            @change="() => onDatePickerInput(value)"
                        ></v-select>
                    </div>
                </v-card>
                <div class="d-flex align-start justify-space-between">
                    <v-card class="d-flex flex-column align-end w-45">
                        <div><v-btn text small @click="clearDatePicker">Очистить</v-btn></div>
                        <v-date-picker
                            :key="updateIndex"
                            v-model="value"
                            range
                            color="purple darken-0"
                            full-width
                            no-title
                            :weekday-format="weekdayFormat"
                            :first-day-of-week="1"
                            :events="getCalendarEvents"
                            class="event-calendar"
                            @input="onDatePickerInput"
                        ></v-date-picker>
                    </v-card>
                    <v-card class="d-flex flex-column w-50 px-2" min-height="100px">

                        <v-progress-linear
                            v-if="userEventsLoading"
                            height="2"
                            color="purple darken-2"
                            class="mt-10 w-100"
                            indeterminate
                        />
                        <template v-else>
                            <div class="d-flex justify-end w-100">
                                <v-btn
                                    v-if="isCanEditCalendarSection"
                                    dark
                                    small
                                    color="purple darken-2"
                                    class="mt-5 mr-5"
                                    @click="dialogs.addEvent = true"
                                >Добавить мероприятие</v-btn>
                            </div>
                            <template v-if="value.length > 0">

                                <event-calendar-legend
                                    label="Даты ВПР в школе"
                                    :items="eventsBySelectedDay.filter(item => item.category === 'test')"
                                    opened
                                    removable
                                    @click:remove="removeEvent"
                                    class="my-3"
                                />   
                                <event-calendar-legend label="Даты ВПР по приказу" :items="eventsBySelectedDay.filter(item => item.category === 'order_to_test')" opened class="my-3" />   
                                <event-calendar-legend label="Контрольные работы" :items="eventsBySelectedDay.filter(item => item.category === 'control')" opened class="my-3" />  
                            </template>
                            <div v-else class="grey--text pa-5">Для отображения списка мероприятий выберите диапазон дат.</div>
                        </template>
                    </v-card>
                </div>
            </template>
        </div>

        <v-dialog
            v-if="isCanEditCalendarSection"
            v-model="dialogs.addEvent"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-toolbar dark color="purple darken-2">
                    <v-toolbar-title>Добавить мероприятие</v-toolbar-title>
                </v-toolbar>
                <calendar-event-form
                    v-if="dialogs.addEvent"
                    :subjects="subjects"
                    :check-date="checkDate"
                    class="pa-4"
                    @close="onFormClose"
                />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import EventCalendarLegend from '@/components/event-calendar/Legend.vue'
import CalendarEventForm from '@/components/forms/CalendarEventForm/Index.vue'
import TimeHelper from '@/helpers/TimeHelper'
import isUserCanMixin from '@/mixins/isUserCanMixin'
import retrieveOrLoadMixin from '@/mixins/retrieveOrLoadMixin'

const categoryToColor = {
    test: 'orange',
    order_to_test: 'green',
    control: 'red'
}

export default {
    name: 'EventCalendar',
    mixins: [ isUserCanMixin, retrieveOrLoadMixin ],
    components: { EventCalendarLegend, CalendarEventForm },
    data () {
    	return {
            filter: {
                subject: null,
                grade: null
            },
            loading: {
                fetch: false
            },
            dialogs: {
                addEvent: false
            },
            updateIndex: 0,
    		value: [this.$moment().toISOString().substr(0, 10)], // today
            eventsBySelectedDay: []
    	}
    },
    computed: {
        calendarEvents () {
            return this.$store.state.calendar_event.defaultItems?.map(o => {
                o.started_at = this.$moment(o.started_at_formatted, 'YYYY-MM-DD').startOf('day').unix()
                o.ended_at = this.$moment(o.ended_at_formatted, 'YYYY-MM-DD').endOf('day').unix()
                o.color = categoryToColor[o.category] || true
                return o
            }) || []
        },
        userEventsLoading () {
            return this.$store.state.calendar_event.loading
        },
        userEvents () {
            return this.$store.state.calendar_event.items.map(item => {
                return {
                    ...item,
                    color: categoryToColor[item.category] || true
                }
            })
        },
        eventsRanges () {
            const rangesInString = this.calendarEvents.map(o => `${o.started_at}_${o.ended_at}`)
            return Array.from(new Set(rangesInString)).map(string => string.split('_'))
        },
        subjects () {
            const subjects = this.$store.getters['calendar_event/subjects']
            return subjects?.length ? subjects : null
        },
        grades () {
            if (!this.calendarEvents?.length) { return null }
            return Array.from(new Set(this.calendarEvents.map(o => o.grade))).map(value => ({ text: `${value} класс`, value }))
        }
    },
    methods: {
        clearDatePicker () {
            this.value = []
            this.onDatePickerInput(this.value)
        },
        async onDatePickerInput (dates) {
            this.eventsBySelectedDay = this.findEventsBetweenDates(dates)
        },
        getCalendarEvents (date) {
            return Array.from(new Set(this.findEventsBetweenDates(date)?.map(o => o.color)))
        },
        findEventsBetweenDates (dates) {
            let events = []

            if (Array.isArray(dates) && dates.length === 1) {
                // return events
                dates = [dates[0], dates[0]]
            }
            if (Array.isArray(dates) && dates.length === 2) {
                const timestamps = dates.map(date => this.$moment(date).format('X')).sort()
                dates = timestamps.map(ts => this.$moment(parseInt(ts), 'X').format('YYYY-MM-DD'))

                const unixStartOfDay = this.$moment(dates[0], 'YYYY-MM-DD').startOf('day').unix()
                const unixEndOfDay = this.$moment(dates[1], 'YYYY-MM-DD').endOf('day').unix()

                events = [...this.calendarEvents, ...this.userEvents]
                    .filter(event => {
                        const eventStartOfDay = this.$moment(event.started_at, 'X').startOf('day').unix()
                        const eventEndOfDay = this.$moment(event.ended_at, 'X').endOf('day').unix()

                        return  (unixStartOfDay <= eventStartOfDay && unixEndOfDay >= eventEndOfDay) || // Диапазон полностью охватывает начало и конец события
                                (unixStartOfDay <= eventStartOfDay && unixEndOfDay >= eventStartOfDay) || // Диапазон захватывает начало события
                                (unixStartOfDay <= eventEndOfDay && unixEndOfDay >= eventEndOfDay) || // Диапазон захватывает конец события
                                (unixStartOfDay >= eventStartOfDay && unixEndOfDay <= eventEndOfDay) // Диапазон полностью внутри события
                    })
            } else if (typeof dates === 'string') {
                const unixStartOfDay = this.$moment(dates, 'YYYY-MM-DD').startOf('day').unix()
                const unixEndOfDay = this.$moment(dates, 'YYYY-MM-DD').endOf('day').unix()
                events = [...this.calendarEvents, ...this.userEvents]
                    .filter(event => {
                        const eventStartOfDay = this.$moment(event.started_at, 'X').startOf('day').unix()
                        const eventEndOfDay = this.$moment(event.ended_at, 'X').endOf('day').unix()
                        return (unixStartOfDay >= eventStartOfDay && unixEndOfDay <= eventEndOfDay)
                    })
            }

            if (this.filter.grade)
                events = events.filter(o => o.grade === this.filter.grade)

            if (this.filter.subject)
                events = events.filter(o => o.subject === this.filter.subject)

            return events
        },
        async removeEvent (id) {
            try {
                const { success, error } = await this.$store.dispatch('calendar_event/delete', { id })
                
                if (!success) {
                    throw new Error(error)
                }
                this.$emit('snack-bar', { text: 'Мероприятие успешно удалено.' })
            } catch (e) {
                console.error(e)
                this.$emit('snack-bar', { text: 'Удаление мероприятие прервано ошибкой.' })
            } finally {
               this.retrieveOrLoad({ module: 'calendar_event', action: 'list', forceLoad: true })
               .then(() => {
                    this.eventsBySelectedDay = this.findEventsBetweenDates(this.value)
                })
            }
        },
        weekdayFormat (date) {
            return TimeHelper.weekdayFormat(date)
        },
        checkDate (date) {
            const dateInSeconds = this.$moment(date).format('X')
            return this.eventsRanges.some(([started_at, ended_at]) => dateInSeconds >= started_at && dateInSeconds <= ended_at)
        },
        async onFormClose () {
            this.dialogs.addEvent = false
            await this.retrieveOrLoad({ module: 'calendar_event', action: 'list', forceLoad: true })
            this.updateIndex++
        }
    },
    async mounted () {
        await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        await this.retrieveOrLoad({ module: 'calendar_event', action: 'list' })
        this.onDatePickerInput(this.value)
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.event-calendar {
    .v-date-picker-table .v-btn.v-btn--active {
        color: black!important;
        background: white !important;
        border: 2px $purple solid!important;
        
        &::before {
            opacity: 0;
        }
    }
}
</style>