<template>
    <div>
        <div class="d-flex flex-column">
            <div class="d-flex align-center">
                <v-select
                    v-if="subjects.length"
                    v-model="filter.subject"
                    :items="subjects"
                    label="Предмет"
                    class="mr-5"
                ></v-select>
                <v-select
                    v-model="filter.grade"
                    :items="grades"
                    label="Параллель"
                ></v-select>
            </div>
        </div>
        <v-expansion-panels v-if="groupsByPickedParallel.length" :key="`${filter.grade}_${filter.subject}`" multiple class="mb-6">
            <v-expansion-panel
                v-for="group in groupsByPickedParallel"
                :key="group.id"
                class="v-expansion-panel"
            >
                <v-expansion-panel-header class="fw-500 py-0" style="min-height: 50px;">
                    Класс {{ group.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <group-form
                        v-for="(event, index) in events.filter(e => e.group_id === group.id)"
                        :key="index"
                        :model="event"
                        :check-date="checkDate"
                    />
                    <v-btn
                        color="purple darken-2"
                        small
                        dark
                        class="mt-5"
                        @click.prevent.stop="createEvent(group)"
                    >
                        Добавить мероприятие
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div v-else class="grey--text">Классов в указанной параллели не найдено.</div>

        <v-container>
            <v-row>
                <v-col class="d-flex align-center">
                    <v-spacer />
                    <v-btn
                        outlined
                        small
                        @click.prevent.stop="$emit('close')"
                    >
                        Закрыть
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import retrieveOrLoadMixin from '@/mixins/retrieveOrLoadMixin'
import GroupForm from './GroupForm.vue'

export default {
    components: { GroupForm },
    mixins: [ retrieveOrLoadMixin ],
    props: {
        subjects: { type: Array, default: () => [] },
        checkDate: { type: Function, default: (() => true) }
    },
    data () {
        return {
            events: [],
            filter: {
                subject: null,
                grade: null
            }
        }
    },
    computed: {
        groups () {
            return this.$store.state.group.items
        },
        grades () {
            return [4, 5, 6, 7, 8, 10].map(v => {
                return {
                    text: `${v} параллель`,
                    value: v
                };
            })
        },
        groupsByPickedParallel () {
            return this.groups.filter(g => g.grade === this.filter.grade)
        }
    },
    created () {
        this.retrieveOrLoad({ module: 'group', action: 'fetch', fields: 'grade,name,student_id' })
    },
    methods: {
        createEvent (group) {
            this.events.push({
                ...this.filter,
                group_id: group.id
            })
        }
    }
}
</script>